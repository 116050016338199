
import React, { Suspense, lazy, useEffect } from 'react';
import './App.css';
import { Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';

const Header = lazy(() => import('./component/Header'));
const Footer = lazy(() => import('./component/Footer'));
const Home = lazy(() => import('./pages/Home'));
const About = lazy(() => import('./pages/About'));
const Portfolio = lazy(() => import('./pages/Portfolio'));
const Contact = lazy(() => import('./pages/Contact'));

const Whatsappicon = lazy(() => import('./component/Whatsappicon'));
const Onemovie = lazy(() => import('./component/portfolio/Onemovie'));





const ScrollToTop = React.memo(() => {
  const { pathname } = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
});

function App() {
  return (
    <Router>
      {/* Use Suspense to wrap components and show a fallback while loading */}
      <Suspense fallback={<div>Loading...</div>}>
        {/* Use React Fragments to avoid additional DOM nodes */}
        <>
          <Header />
          <ScrollToTop />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/portfolio' element={<Portfolio />} />
            <Route path='/contact' element={<Contact />} />
            <Route path="/portfolio/portfolioitems/:itemId" element={<Onemovie />} />
          </Routes>
          <Footer />
          <Whatsappicon />
        </>
      </Suspense>
    </Router>
  );
}

export default App;
